import React, { useEffect, useRef, useState } from 'react'
import anime from 'animejs'

import onWindowResize from '../hooks/OnWindowResize'

import * as errorStyles from './error.module.css'

const cubeHeight = 100
const cubeWidth = 100

const onMouseOverAnimate = (event) => {
  anime({
    targets: event.currentTarget,
    top: -80,
    direction: 'alternate',
  })
}

const renderCubes = (height) => {
  const [width] = onWindowResize()
  const columns = Math.round(width / cubeWidth) + 1
  const rows = Math.floor((height / cubeHeight) * 2.1)

  return Array.from(Array(rows).keys()).map((group, y) => {
    return (
      <div key={y} className={errorStyles.row}>
        {Array.from(Array(columns).keys()).map((item, x) => {
          return (
            /* eslint-disable */
            <div
              key={x}
              className={`${errorStyles.cube}`}
              onMouseEnter={onMouseOverAnimate}
              onTouchStart={onMouseOverAnimate}
              role="button"
            >
              <div
                className={`${errorStyles.face} ${errorStyles.top}`}
                style={randomizeColors()}
              />
              <div
                className={`${errorStyles.face} ${errorStyles.front}`}
                style={randomizeColors()}
              />
              <div
                className={`${errorStyles.face} ${errorStyles.right}`}
                style={randomizeColors()}
              />
            </div>
            /* eslint-enable */
          )
        })}
      </div>
    )
  })
}

const randomizeColors = () => {
  const randomColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16)

  return {
    background: randomColor,
  }
}

const Error = () => {
  const [height, setHeight] = useState(null)
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.getBoundingClientRect().height)
    }
  }, [containerRef])

  return (
    <div className={errorStyles.container} ref={containerRef}>
      <figure className={errorStyles.scene}>{renderCubes(height)}</figure>
    </div>
  )
}

export default Error
