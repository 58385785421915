import React from 'react'

import Layout from '../components/layout'
import Error from '../components/error'

const NotFoundPage = () => (
  <Layout>
    <article>
      <h1>This content couldn't be found.</h1>
      <p>Here's something random instead...</p>
    </article>
    <Error />
  </Layout>
)

export default NotFoundPage
